import styled from "styled-components";
import { useState } from "react";

const SecondHeading = styled.h2`
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
  font-size: 2.4rem;
  font-weight: 400;
`;

const Text = styled.p`
  text-align: center;
  margin-top: 2rem;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  line-height: 36px;
  text-align: justify;
  font-size: 1.1rem;

  @media (max-width: 768px) {
    width: 90%;
    font-size: 0.9rem;
  }
  span {
    font-weight: 600;
  }
`;

const Map = styled.iframe`
  width: 100%;
  height: 400px;
  border: none;
  margin-top: 6rem;
`;

const GalleryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  width: 65%;
  margin: 4rem auto;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const GalleryImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.02);
  }

  &:nth-child(1) {
    grid-column: span 2;
    grid-row: span 2;
  }
  &:nth-child(2) {
    grid-column: span 1;
    grid-row: span 1;
  }
  &:nth-child(3) {
    grid-column: span 1;
    grid-row: span 1;
  }
  &:nth-child(4) {
    grid-column: span 2;
    grid-row: span 1;
  }
  &:nth-child(5) {
    grid-column: span 1;
    grid-row: span 1;
  }

  @media (max-width: 768px) {
    grid-column: span 1 !important;
    grid-row: span 1 !important;
  }
`;

export const MainZalety = styled.div`
  padding: 2rem;
  width: 95%;
  margin: 4rem auto;
  background-color: #fff;
`;

export const ZaletyHeadingMain = styled.h2`
  text-align: center;
  margin-bottom: 4rem;
  font-size: 2.6rem;
  color: #333;
  font-weight: 400;
`;

export const ZaletyGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
`;

export const Zaleta = styled.div`
  border: 1px solid #aaa;
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f9f9f9;

  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`;

export const ZaletaIcon = styled.img`
  width: 50px;
  margin: 1rem auto;
`;

export const ZaletaHeading = styled.h3`
  margin: 1rem 0;
  font-size: 1.2rem;
  color: #444;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const ZaletaInfo = styled.p`
  font-size: 0.95rem;
  line-height: 1.6;
  color: #555;

  @media (max-width: 768px) {
    font-size: 0.85rem;
  }
`;

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  margin: 20px 0;

  @media (max-width: 768px) {
    max-height: 50dvh;
  }
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 0;

  th,
  td {
    text-align: center;
    padding: 12px;
    border: 1px solid #ddd;
    font-size: 14px;
  }

  th {
    background-color: #f4f4f4;
    font-weight: bold;
  }

  td a {
    color: #007bff;
    text-decoration: none;
  }

  td a:hover {
    text-decoration: underline;
  }

  .taken {
    color: #ff5e5e;
    font-weight: bold;
  }
`;

const StyledTh = styled.th`
  font-weight: bold;
  text-transform: uppercase;
  color: #333;
`;

const SpecyfikacjaContainer = styled.div`
  position: relative;
  padding: 1rem;
  margin-bottom: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpecyfikacjaDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  flex-wrap: wrap;
  background-color: #030821;
  border-radius: 12px;
  padding: 1rem;
  -webkit-box-shadow: 9px 7px 17px -12px rgba(66, 68, 90, 1);
  -moz-box-shadow: 9px 7px 17px -12px rgba(66, 68, 90, 1);
  box-shadow: 9px 7px 17px -12px rgba(66, 68, 90, 1);
  width: 50%;
  height: 500px;
  overflow: auto;

  @media (max-width: 1599px) {
    width: 75%;
  }

  @media (max-width: 1024px) {
    width: 100%;
    height: 650px;
  }
`;

const SpecyfikacjaImage = styled.div`
  background-size: cover;
  background-position: center;
  flex: 1;
  border-radius: 6px;
  min-width: 320px;
  height: 420px;

  @media (max-width: 1024px) {
    height: 320px;
  }
`;

const SpecyfikacjaDescription = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  min-width: 320px;
  padding: 0.5rem;
  color: white;

  h4 {
    font-size: 1.3rem;
    text-align: center;
  }

  p {
    font-size: 0.9rem;
  }
`;

const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  z-index: 1;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }

  &.left {
    left: 20%;

    @media (max-width: 1599px) {
      left: 5%;
    }

    @media (max-width: 1024px) {
      left: 0%;
      font-size: 1rem;
      width: 24px;
      height: 24px;
    }
  }

  &.right {
    right: 20%;

    @media (max-width: 1599px) {
      right: 5%;
    }

    @media (max-width: 1024px) {
      right: 0%;
      font-size: 1rem;
      width: 24px;
      height: 24px;
    }
  }
`;

const specyfikacjaData = [
  {
    photo: "/nad_stawami/3domy.JPG",
    heading: "Fundamenty, ściany piwnic, kondygnacji nadziemnych",
    text: "Budynek niepodpiwniczony, ławy fundamentowe żelbetowe (C20/25, 60X40, 80X40), Ściany fundamentowe z bloczków betonowych. Konstrukcja ścian nośnych zewnętrznych - bloczki gazobetonowe gr. 24cm. Izolowane z zewnątrz styropianem typu EPS 032. Wykończenie tynkiem cienkowarstwowym na siatce. Konstrukcja ścian nośnych wewnętrznych – bloczki gazobetonowe gr. 24cm i bloczki silikatowe gr. 18cm, tynkowane. Ściany działowe z płyt g-k na stelarzach systemowych i bloczków gazobetonowych gr. 12cm i 8cm. Tynki gipsowe kat.2, w pomieszczeniach mokrych cementowo-wapienne kat.2",
  },
  {
    photo: "/nad_stawami/Segment.JPG",
    heading: "Stropy, dach, wykończenie elewacji.",
    text: "Strop międzykondygnacyjny żelbetowy prefabrykowany typu Teriva Panel. Izolacja akustyczna gr. 10 cm. Wylewka betonowa 6 cm. Nad piętrem sufit podwieszany na konstrukcji krzyżowej, izolowany termicznie. Schody żelbetowe wylewane. Dach o konstrukcji drewnianej kratownicowej - dźwigary dachowe prefabrykowane, kryty blachą na rąbek, powlekaną. Elewacja budynku wykonana w technologii tynku cienkowarstwowego, w kolorze białym i szarym, wg. rys. elewacji. Detale wykończenia elewacji wg. rys. warsztatowych. Fragmenty elewacji wykończone naturalną okładziną drewnianą. Parapety zewnętrzne i obróbki detali elewacji z blachy powlekanej w kolorze grafitowym. Stolarka okienna zewnętrzna z profili PCV, okna trzyszybowe. Drzwi zewnętrzne wzmocnione, z dodatkową wkładką izolacyjną - GERDA.",
  },
  {
    photo: "/nad_stawami/goraPokoj.JPG",
    heading: "Okna wykonane w systemie Salamander.",
    text: "Wyjątkowy profil, bluEvolution 82. Innowacyjna 6-cio komorowa konstrukcja, szerokość zabudowy 82mm. Specjalny system uszczelek zapewnia doskonałą izolacyjność terminczą. Okucia Wink Haus w standardzie jedno zabezpieczenie antywłamaniowe regulacja docisku uszczelki, uchył trójstopniowy. Ciepła ramka międzyszbowa 7040, zwiększa izolacyjność cieplna całego okna, niwelując efekt skraplania pary wodnej. Uszczelka środkowa poprawia włościwości termiczne jak i również parametry okustyczne okna. Pakiet trzyszybowy U = 0,5, kolor okien biały uszczelka szara, klamka biała.",
  },
  {
    photo: "/nad_stawami/dolSchodyInstalacja.JPG",
    heading:
      "Wyposażenie instalacyjne, izolacje przeciwwilogciowe, termiczne i akustyczne.",
    text: "Piec gazowy dwufunkcyjny z zasobnikiem 120l zasilany z sieci ulicznej. Ogrzewanie podłogowe z opcją sterowania strefowego. Budynek przygotowany do montażu wentylacji mechanicznej z odzyskiem ciepła. Budynek zasilany w wodę z sieci ulicznej. Budynek przystosowany do montażu systemu filtracji i zmiękczania wody. Na zewnątrz budynku ujęcia wody do podlewania ogrodu. Budynek przyłączony do sieci kanalizacyjnej w sieci ulicznej. W budynku okablowanie elektryczne w układzie gwiazdy (przystosowane do sterowania centralnego) i okablowanie strukturalne ,inteligentny dom. Ławy fundamentowe - izolacja pcv. Ściany fundamentowe - obustronnie dysperbit, izolacja termiczna ze styropianu EPS, gr. 12cm. Ściany zewnętrzne - izolacja termiczna ze styropianu EPS, 15 / 20cm. Dach – folia wysokoparoprzepuszczalna, izolacja termiczna z wełny mineralnej akustycznej gr. 25cm, Izolacja ściany konstrukcyjnej między budynkowej na poddaszu – wełna mineralna twarda.",
  },
];

const NadStawami = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? specyfikacjaData.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === specyfikacjaData.length - 1 ? 0 : prevIndex + 1
    );
  };
  return (
    <>
      <GalleryContainer>
        <GalleryImage src="/nad_stawami/duzoKostki.jpg" alt="Gallery Image 1" />
        <GalleryImage
          src="/nad_stawami/kostkaJeden.jpg"
          alt="Gallery Image 1"
        />
        <GalleryImage
          src="/nad_stawami/bocznyWidok.jpg"
          alt="Gallery Image 1"
        />
        <GalleryImage
          src="/nad_stawami/srodekSegment.jpg"
          alt="Gallery Image 1"
        />
        <GalleryImage src="/nad_stawami/zSamolotu.jpg" alt="Gallery Image 1" />
      </GalleryContainer>

      <Text>
        <span>Osiedle nad Stawami</span> cechuje się unikalnym klimatem.
        Umiejscowione jest w sąsiedztwie lasu, łąk oraz stawów. Osiedle posiada
        sprzyjającą lokalizację. Do przystanku autobusowego, z którego możemy
        dostać się do stacji WKD czy szkoły podstawowej dzieli Was zaledwie
        kilka minut. Nasze projekty są realizowane z najwyższą dbałością o
        szczegóły, od roku 2020 tworzymy i budujemy dla Was klimatyczne,
        energooszczędne segmenty z intstalacjami pod inteligentny dom.
        Zapraszamy do kontaktu w sprawie zakupu mieszkań poprzez formularz
        kontaktowy na naszej stronie.{" "}
        <span>Jesteśmy deweloperem z rachunkiem powierniczym</span>.
      </Text>

      <MainZalety>
        <ZaletyHeadingMain>Zalety</ZaletyHeadingMain>
        <ZaletyGrid>
          <Zaleta>
            <ZaletaHeading>Osiedle Zamknięte</ZaletaHeading>
            <ZaletaIcon src="/nad_stawami/house.png" alt="House Icon" />
            <ZaletaInfo>
              20 lokali mieszkalnych na zamkniętym osiedlu w Grodzisku
              Mazowieckim przy ul. Łagodnej. Segmenty o powierzchni 126m2- 145m2
              usytuowane są na działce od 200-500m2. Każdy segment posiada
              własny podjazd na samochód. Domy budowane są w technologii
              energooszczędnej w kategorii B. Segmenty zostaną oddane do użytku
              już w marcu.
            </ZaletaInfo>
          </Zaleta>

          <Zaleta>
            <ZaletaHeading>Komunikacja</ZaletaHeading>
            <ZaletaIcon src="/nad_stawami/train.png" alt="Train Icon" />
            <ZaletaInfo>
              15 minut do autostrady A2 z wygodnym dojazdem do Warszawy lub
              Łodzi. 5 minut do WKD Okrężna oraz 10 minut do stacji PKP Grodzisk
              Mazowiecki- w ciągu 30 minut znajdziesz się w centrum Warszawy.
            </ZaletaInfo>
          </Zaleta>

          <Zaleta>
            <ZaletaHeading>Relaks i sport</ZaletaHeading>
            <ZaletaIcon src="/nad_stawami/sports.png" alt="Sports Icon" />
            <ZaletaInfo>
              Dzięki temu, że Grodzisk jest najbogatszą gminą na Mazowszu
              oferowane są rozrywki na wysokim poziomie: Basen, kino, galeria
              handlowa, parki sportowo-rekreacyjne, ale także restauracje- to
              wszystko w zasięgu ręki.
            </ZaletaInfo>
          </Zaleta>

          <Zaleta>
            <ZaletaHeading>Szkoły, przedszkola i żłobki</ZaletaHeading>
            <ZaletaIcon
              src="/nad_stawami/graduation-cap.png"
              alt="Graduation Icon"
            />
            <ZaletaInfo>
              Do centrum Grodziska Mazowieckiego dzieli tylko 5 km gdzie
              znajdują się szkoły podstawowe, żłobki oraz przedszkola.
              Najbliższa szkoła podstawowa będzie znajdowała się zaledwie 500m
              od osiedla.
            </ZaletaInfo>
          </Zaleta>

          <Zaleta>
            <ZaletaHeading>Kultura</ZaletaHeading>
            <ZaletaIcon src="/nad_stawami/masks.png" alt="Masks Icon" />
            <ZaletaInfo>
              W Grodzisku Mazowieckim nie można się nudzić! Ośrodek Kultury ma
              szanse stać się przestrzenią wymiany wiedzy. Mediateka,
              biblioteka, organizowane teatry dla dzieci, kabarety, wystawy
              fotograficzne, rzeźbiarskie a także malarskie pozwolą na
              zaspokojenie potrzeb rozrywkowych.
            </ZaletaInfo>
          </Zaleta>

          <Zaleta>
            <ZaletaHeading>Bliżej przyrody</ZaletaHeading>
            <ZaletaIcon src="/nad_stawami/nature.png" alt="Nature Icon" />
            <ZaletaInfo>
              Segmenty położone są tuż nad stawami w Szczęsnych wśród pięknej
              otuliny lasu. Na terenie naszego powiatu Grodziskiego znajduje się
              wiele pomników przyrody, gdzie wraz z rodziną w wolnym czasie
              można je podziwiać.
            </ZaletaInfo>
          </Zaleta>
        </ZaletyGrid>
      </MainZalety>

      <SecondHeading>Specyfikacja</SecondHeading>

      <SpecyfikacjaContainer>
        <ArrowButton className="left" onClick={handlePrev}>
          &lt;
        </ArrowButton>

        <SpecyfikacjaDiv>
          <SpecyfikacjaImage
            style={{
              backgroundImage: `url(${specyfikacjaData[currentIndex]?.photo})`,
            }}
          />
          <SpecyfikacjaDescription>
            <h4>{specyfikacjaData[currentIndex]?.heading}</h4>
            <p>{specyfikacjaData[currentIndex]?.text}</p>
          </SpecyfikacjaDescription>
        </SpecyfikacjaDiv>

        <ArrowButton className="right" onClick={handleNext}>
          &gt;
        </ArrowButton>
      </SpecyfikacjaContainer>

      <ZaletyHeadingMain>Dostępność Lokali</ZaletyHeadingMain>
      <TableWrapper>
        <StyledTable>
          <thead>
            <tr>
              <StyledTh id="nrLOK" scope="col">
                Nr Lok.
              </StyledTh>
              <StyledTh id="powierzchniaLOK" scope="col">
                Powierzchnia m<sup>2</sup>
              </StyledTh>
              <StyledTh id="ogródLOK" scope="col">
                Ogród m<sup>2</sup>
              </StyledTh>
              <StyledTh id="miejscaLOK" scope="col">
                Miejsca postojowe
              </StyledTh>
              <StyledTh id="rzutLOK" scope="col">
                Rzuty lokali
              </StyledTh>
              <StyledTh id="statusLOK" scope="col">
                Status
              </StyledTh>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">12H/1</th>
              <td>
                145,83 m<sup>2</sup>
              </td>
              <td>
                509.52 m<sup>2</sup>
              </td>
              <td>2</td>
              <td>
                <a
                  href="/SZCZESNE/PDFy/RZUTY1A.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Otwórz pdf
                </a>
              </td>
              <td style={{ color: "red" }}>Sprzedany</td>
            </tr>
            <tr>
              <th scope="row">12H/2</th>
              <td>
                125.89 m<sup>2</sup>
              </td>
              <td>
                233 m<sup>2</sup>
              </td>
              <td>2</td>
              <td>
                <a
                  href="/SZCZESNE/PDFy/RZUTY1B.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Otwórz pdf
                </a>
              </td>
              <td style={{ color: "red" }}>Sprzedany</td>
            </tr>
            <tr>
              <th scope="row">12J/1</th>
              <td>
                125.89 m<sup>2</sup>
              </td>
              <td>
                233 m<sup>2</sup>
              </td>
              <td>2</td>
              <td>
                <a
                  href="/SZCZESNE/PDFy/RZUTY2A.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Otwórz pdf
                </a>
              </td>
              <td style={{ color: "red" }}>Sprzedany</td>
            </tr>
            <tr>
              <th scope="row">12J/2</th>
              <td>
                145,83 m<sup>2</sup>
              </td>
              <td>
                524.5 m<sup>2</sup>
              </td>
              <td>2</td>
              <td>
                <a
                  href="/SZCZESNE/PDFy/RZUTY2B.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Otwórz pdf
                </a>
              </td>
              <td style={{ color: "red" }}>Sprzedany</td>
            </tr>
            <tr>
              <th scope="row">12K/2</th>
              <td>
                145,83 m<sup>2</sup>
              </td>
              <td>
                524.5 m<sup>2</sup>
              </td>
              <td>2</td>
              <td>
                <a
                  href="/SZCZESNE/PDFy/RZUTY3A.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Otwórz pdf
                </a>
              </td>
              <td style={{ color: "red" }}>Sprzedany</td>
            </tr>
            <tr>
              <th scope="row">12K/1</th>
              <td>
                125.89 m<sup>2</sup>
              </td>
              <td>
                233 m<sup>2</sup>
              </td>
              <td>2</td>
              <td>
                <a
                  href="/SZCZESNE/PDFy/RZUTY3B.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Otwórz pdf
                </a>
              </td>
              <td style={{ color: "red" }}>Sprzedany</td>
            </tr>
            <tr>
              <th scope="row">12L/2</th>
              <td>
                125.89 m<sup>2</sup>
              </td>
              <td>
                233 m<sup>2</sup>
              </td>
              <td>2</td>
              <td>
                <a
                  href="/SZCZESNE/PDFy/RZUTY4A.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Otwórz pdf
                </a>
              </td>
              <td style={{ color: "red" }}>Sprzedany</td>
            </tr>
            <tr>
              <th scope="row">12L/1</th>
              <td>
                145,83 m<sup>2</sup>
              </td>
              <td>
                509,5 m<sup>2</sup>
              </td>
              <td>2</td>
              <td>
                <a
                  href="/SZCZESNE/PDFy/RZUTY4B.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Otwórz pdf
                </a>
              </td>
              <td style={{ color: "red" }}>Sprzedany</td>
            </tr>
            <tr>
              <th scope="row">12M/1</th>
              <td>
                145,83 m<sup>2</sup>
              </td>
              <td>
                509,5 m<sup>2</sup>
              </td>
              <td>2</td>
              <td>
                <a
                  href="/SZCZESNE/PDFy/RZUTY4B.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Otwórz pdf
                </a>
              </td>
              <td style={{ color: "red" }}>Sprzedany</td>
            </tr>
            <tr>
              <th scope="row">12M/2</th>
              <td>
                145,83 m<sup>2</sup>
              </td>
              <td>
                509,5 m<sup>2</sup>
              </td>
              <td>2</td>
              <td>
                <a
                  href="/SZCZESNE/PDFy/RZUTY4B.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Otwórz pdf
                </a>
              </td>
              <td style={{ color: "red" }}>Sprzedany</td>
            </tr>
            <tr>
              <th scope="row">12N/1</th>
              <td>
                145,83 m<sup>2</sup>
              </td>
              <td>
                509,5 m<sup>2</sup>
              </td>
              <td>2</td>
              <td>
                <a
                  href="/SZCZESNE/PDFy/RZUTY4B.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Otwórz pdf
                </a>
              </td>
              <td style={{ color: "red" }}>Sprzedany</td>
            </tr>
            <tr>
              <th scope="row">12N/2</th>
              <td>
                145,83 m<sup>2</sup>
              </td>
              <td>
                509,5 m<sup>2</sup>
              </td>
              <td>2</td>
              <td>
                <a
                  href="/SZCZESNE/PDFy/RZUTY4B.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Otwórz pdf
                </a>
              </td>
              <td style={{ color: "red" }}>Sprzedany</td>
            </tr>
            <tr>
              <th scope="row">12L/1</th>
              <td>
                145,83 m<sup>2</sup>
              </td>
              <td>
                509,5 m<sup>2</sup>
              </td>
              <td>2</td>
              <td>
                <a
                  href="/SZCZESNE/PDFy/RZUTY4B.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Otwórz pdf
                </a>
              </td>
              <td style={{ color: "red" }}>Sprzedany</td>
            </tr>
            <tr>
              <th scope="row">12R/1</th>
              <td>
                145,83 m<sup>2</sup>
              </td>
              <td>
                509,5 m<sup>2</sup>
              </td>
              <td>2</td>
              <td>
                <a
                  href="/SZCZESNE/PDFy/RZUTY4B.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Otwórz pdf
                </a>
              </td>
              <td style={{ color: "red" }}>Sprzedany</td>
            </tr>
            <tr>
              <th scope="row">12R/2</th>
              <td>
                145,83 m<sup>2</sup>
              </td>
              <td>
                509,5 m<sup>2</sup>
              </td>
              <td>2</td>
              <td>
                <a
                  href="/SZCZESNE/PDFy/RZUTY4B.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Otwórz pdf
                </a>
              </td>
              <td style={{ color: "red" }}>Sprzedany</td>
            </tr>
            <tr>
              <th scope="row">12P/1</th>
              <td>
                145,83 m<sup>2</sup>
              </td>
              <td>
                509,5 m<sup>2</sup>
              </td>
              <td>2</td>
              <td>
                <a
                  href="/SZCZESNE/PDFy/RZUTY4B.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Otwórz pdf
                </a>
              </td>
              <td style={{ color: "red" }}>Sprzedany</td>
            </tr>
            <tr>
              <th scope="row">12P/2</th>
              <td>
                145,83 m<sup>2</sup>
              </td>
              <td>
                509,5 m<sup>2</sup>
              </td>
              <td>2</td>
              <td>
                <a
                  href="/SZCZESNE/PDFy/RZUTY4B.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Otwórz pdf
                </a>
              </td>
              <td style={{ color: "red" }}>Sprzedany</td>
            </tr>
            <tr>
              <th scope="row">12T/1</th>
              <td>
                145,83 m<sup>2</sup>
              </td>
              <td>
                509,5 m<sup>2</sup>
              </td>
              <td>2</td>
              <td>
                <a
                  href="/SZCZESNE/PDFy/RZUTY4B.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Otwórz pdf
                </a>
              </td>
              <td style={{ color: "red" }}>Sprzedany</td>
            </tr>
            <tr>
              <th scope="row">12T/2</th>
              <td>
                145,83 m<sup>2</sup>
              </td>
              <td>
                509,5 m<sup>2</sup>
              </td>
              <td>2</td>
              <td>
                <a
                  href="/SZCZESNE/PDFy/RZUTY4B.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Otwórz pdf
                </a>
              </td>
              <td style={{ color: "red" }}>Sprzedany</td>
            </tr>
            <tr>
              <th scope="row">12S/1</th>
              <td>
                145,83 m<sup>2</sup>
              </td>
              <td>
                509,5 m<sup>2</sup>
              </td>
              <td>2</td>
              <td>
                <a
                  href="/SZCZESNE/PDFy/RZUTY4B.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Otwórz pdf
                </a>
              </td>
              <td style={{ color: "red" }}>Sprzedany</td>
            </tr>
            <tr>
              <th scope="row">12S/2</th>
              <td>
                145,83 m<sup>2</sup>
              </td>
              <td>
                509,5 m<sup>2</sup>
              </td>
              <td>2</td>
              <td>
                <a
                  href="/SZCZESNE/PDFy/RZUTY4B.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Otwórz pdf
                </a>
              </td>
              <td style={{ color: "red" }}>Sprzedany</td>
            </tr>
          </tbody>
        </StyledTable>
      </TableWrapper>

      <SecondHeading>Localizacja Osiedla</SecondHeading>

      <Text style={{ textAlign: "center" }}>
        <span>Szczęsne koło Grodziska Mazowieckiego</span>, wjazd z ulicy
        Łagodnej.
      </Text>

      <Map
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4903.222205039805!2d20.652361000000003!3d52.086809!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47194880a0540a93%3A0x219e306a08b4176!2s%C5%81agodna%2C%2005-825!5e0!3m2!1spl!2spl!4v1732542320207!5m2!1spl!2spl"
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></Map>
    </>
  );
};

export default NadStawami;
