import styled from "styled-components";

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  margin-top: 4rem;
  text-align: center;
  padding: 1rem;

  h2 {
    font-size: 2.6rem;
    font-weight: 500;

    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }

  h3 {
    font-size: 1.4rem;
    font-weight: 500;
    width: 70%;

    @media (max-width: 768px) {
      font-size: 1.4rem;
      width: 100%;
    }
  }

  h4 {
    font-size: 1.4rem;
    font-weight: 500;

    @media (max-width: 768px) {
      font-size: 1.15rem;
    }
  }

  p {
    font-size: 1.1rem;
  }

  img {
    width: 240px;
  }

  ul {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    li {
      padding: 1rem;
      text-align: left;
    }
  }
`;

const Finansowanie = () => {
  return (
    <Container>
      <h2>Trust Finance</h2>
      <img src="https://trustfinance.pl/wp-content/uploads/2024/10/ahc.png" />
      <h3>
        Firma Trust Finance została założona z inicjatywy Marcina Warownego i
        tworzy zespół ekspertów z długoletnim doświadczeniem w branży
        finansowej, którzy postanowili połączyć swoje siły, aby pomóc swoim
        Klientom w dobraniu najlepszych ofert finansowych dostępnych na rynku.
      </h3>
      <p>
        Do każdego Klienta podchodzimy indywidualnie. Przeprowadzamy szczegółową
        analizę potrzeb, co pozwala nam na znalezienie najlepszego i zarazem
        najtańszego rozwiązania.
      </p>
      {/* <h4>ATUTY WSPÓŁPRACY Z NASZYM EKSPERTEM:</h4> */}
      <ul>
        {/* <li>
          bezpłatny, niezależny i bezstronny dobór najlepszych ofert
          kredytowych, indywidualna opieka,
        </li>
        <li>ponad 20 banków w jednym miejscu,</li>
        <li>
          prowadzenie całego procesu kredytowego z weryfikacją umów kredytowych
          i dokumentów nieruchomości,
        </li>
        <li>spotkanie w dogodnym miejscu i terminie,</li>
        <li>
          szybki kredyt dzięki dużemu doświadczeniu oraz dostępności do
          uproszczonych procedur.
        </li> */}
      </ul>

      <div>
        <p>Kontakt do Trust Finance</p>
        <p>email: biuro@trustfinance.pl</p>
        <p>tel: 731-869-003</p>
      </div>
    </Container>
  );
};

export default Finansowanie;
