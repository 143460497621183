import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const MainPhoto = styled.div`
  background-image: url("/polna/zewnatrz_polna.jpg");
  background-size: cover;
  background-position: 20% 70%;
  height: 100vh;
  width: 100%;
  margin-top: 6rem;
  padding: 2rem;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(to top, #030821, transparent);
  }

  @media (max-width: 649px) {
    min-height: 60vh;
  }

  div {
    position: absolute;
    z-index: 222;
    bottom: 10%; /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */
    width: 100%;

    transform: translate(-50%, -50%);
  }

  h1 {
    position: relative;
    color: white;
    font-size: 6rem;
    font-weight: 300;
    z-index: 2;
    letter-spacing: 3px;

    @media (max-width: 1024px) {
      font-size: 3rem;
      letter-spacing: 1px;
    }
  }

  p {
    position: relative;
    color: white;
    font-size: 1.6rem;
    font-weight: 300;
    z-index: 2;
    letter-spacing: 2px;
    margin-top: 1rem;

    @media (max-width: 1024px) {
      margin-top: 0;
      font-size: 1.2rem;
    }
  }

  button {
    position: relative;
    z-index: 5;
    width: 240px;
    background-color: transparent;
    border: 2px solid white;
    border-radius: 6px;
    padding: 1rem;
    text-align: center;
    text-transform: uppercase;
    color: white;
    margin-top: 2rem;
    cursor: pointer;

    @media (max-width: 1024px) {
      padding: 0.75rem;
    }
  }
`;

const SecondHeading = styled.h2`
  text-align: center;
  margin-top: 6rem;
  font-size: 2.4rem;
  font-weight: 400;
`;

const Text = styled.p`
  text-align: center;
  margin-top: 2rem;
`;

const Map = styled.iframe`
  width: 100%;
  height: 400px;
  border: none;
  margin-top: 6rem;
`;

const NaszeInwestycje = styled.section`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;

  h2 {
    text-align: center;
    font-size: 2.4rem;
    font-weight: 400;
  }
`;

const InwestycjeContainer = styled.div`
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 4rem;
`;

const Inwestycja = styled.div`
  background-color: #f2f2f2;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  border-radius: 1rem;
  min-height: 600px;
  width: 380px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
    background: white;
  }

  img {
    width: 320px;
  }
`;

const OpisFirmy = styled.div`
  background-color: #030821;
  padding: 4rem 2rem;

  @media (max-width: 768px) {
    padding: 2rem;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const OpisFirmyHeading = styled.h2`
  text-align: center;
  font-size: 2.4rem;
  color: white;
  font-weight: 500;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 1.6rem;
  }
`;

const OpisFirmyTextFotka = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 2rem;
  max-width: 1200px;

  img {
    border-radius: 12px;
    flex: 2;
    max-width: 1020px;

    @media (max-width: 989px) {
      width: 400px;
    }

    @media (max-width: 589px) {
      width: 98%;
    }
  }
`;

const OpisFirmyText = styled.p`
  margin-top: 2rem;
  color: white;
  text-align: justify;
  margin-left: auto;
  margin-right: auto;
  flex: 2;
  line-height: 30px;
  font-size: 1.25rem;
  min-width: 520px;

  @media (max-width: 1399px) {
    font-size: 0.9rem;
    line-height: 22px;
  }

  @media (max-width: 589px) {
    min-width: 98%;
    margin-top: 0rem;
  }
`;

const Lokale = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 20%;
  padding-right: 20%;
  background-color: #030821;
  min-height: 420px;

  @media (max-width: 1399px) {
    padding-left: 10%;
    padding-right: 10%;
  }

  @media (max-width: 1024px) {
    padding-left: 5%;
    padding-right: 5%;
  }
`;

const LokaleLeft = styled.div`
  flex: 1;
  min-width: fit-content;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const PoznajInwestycje = styled.p`
  font-size: 1.1rem;
  color: rgb(42, 112, 212);
  text-transform: uppercase;
`;

const LokaleLeftHeader = styled.p`
  font-size: 7rem;
  color: white;

  @media (max-width: 1024px) {
    font-size: 5rem;
  }
`;

const LokaleRight = styled.div`
  flex: 2;
  min-width: 420px;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    min-width: 100%;
  }

  p {
    font-size: 1.25rem;
    line-height: 2rem;
    color: rgb(181, 185, 204);
    font-weight: 200;

    max-width: 860px;

    @media (max-width: 1024px) {
      font-size: 1rem;
      line-height: 1.8rem;
    }
  }
`;

const UnderLokale = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  padding-top: 2rem;
  padding-bottom: 6rem;
  padding-left: 20%;
  padding-right: 20%;
  background-color: #030821;
  min-height: 420px;

  @media (max-width: 1399px) {
    padding-left: 10%;
    padding-right: 10%;
  }

  @media (max-width: 1024px) {
    padding-left: 5%;
    padding-right: 5%;
  }
`;

const UnderLokaleLeft = styled.div`
  flex: 1;
  min-width: 420px;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    min-width: 100%;
  }

  p {
    font-size: 1.25rem;
    line-height: 2rem;
    color: rgb(181, 185, 204);
    font-weight: 200;

    max-width: 860px;

    @media (max-width: 1024px) {
      font-size: 1rem;
      line-height: 1.8rem;
    }
  }
`;

const UnderLokaleRight = styled.div`
  flex: 2;
  min-width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 620px;
    height: 420px;
    border-radius: 12px;

    @media (max-width: 1024px) {
      width: 100%;
      height: auto;
    }
  }
`;

const Home = () => {
  const navigate = useNavigate();
  return (
    <>
      <MainPhoto>
        <div>
          <h1>Osiedle Polna</h1>
          <p>Osiedle z Klimatem</p>
          <button
            onClick={() => {
              navigate("/osiedle-polna");
            }}
          >
            Zobacz Inwestycje
          </button>
        </div>
      </MainPhoto>

      <Lokale>
        <LokaleLeft>
          <div>
            <PoznajInwestycje>— Poznaj Inwestycje!</PoznajInwestycje>
            <LokaleLeftHeader>18 Lokali!</LokaleLeftHeader>
          </div>
        </LokaleLeft>
        <LokaleRight>
          <p>
            W ramach inwestycji p.n „Osiedle Polna” powstanie 9 budynków
            mieszkalnych dwulokalowych w zabudowie bliźniaczej- łącznie 18
            lokali. Funkcjonalne i ponadczasowe mieszkania o powierzchni 89,47m2
            pozwalają na komfortowe zamieszkanie 4-ro lub 5-cio osobowej
            rodziny. Do każdego mieszkania przynależy ok. 500 m2 działki.
          </p>
        </LokaleRight>
      </Lokale>

      <UnderLokale>
        <UnderLokaleLeft>
          <p>
            Cała inwestycja podzielona jest na 3 etapy, które są niezależne od
            siebie. Pierwszy etap oddajemy już w czerwcu 2025r !! Jesteśmy
            głównym inwestorem inwestycji co pozwala nam na ustalaniu
            konkurencyjnych cen !!
          </p>
        </UnderLokaleLeft>

        <UnderLokaleRight>
          <img src="/lokale.jpg" />
        </UnderLokaleRight>
      </UnderLokale>

      <NaszeInwestycje>
        <h2>Nasze Inwestycje</h2>

        <InwestycjeContainer>
          <Inwestycja
            onClick={() => {
              navigate("/osiedle-polna");
            }}
          >
            <div>
              <p style={{ fontSize: "1.6rem", textAlign: "center" }}>
                Osiedle Polna
              </p>
              <p
                style={{
                  fontSize: "1.3rem",
                  textAlign: "center",
                  color: "rgb(42, 112, 212)",
                }}
              >
                (W Realizacji)
              </p>
            </div>
            <img src="/homepage/polna_plan_3.png" />
            <p style={{ fontSize: ".95rem" }}>
              Nasza inwestycja "Osiedle Polna" położona jest w Osowcu gmina
              Żabia Wola w północno - zachodniej części gminy. Osiedle to
              znajduje się na przeciwko naszej poprzedniej inwestycji - "Osiedle
              Dworska". Osowiec ciągnie się od wschodu do zachodu przeważnie po
              jednej stronie drogi, wysadzonej dużymi topolami, które nadają
              bardzo duży urok wsi.
            </p>
          </Inwestycja>
          <Inwestycja
            onClick={() => {
              navigate("/nad-stawami");
            }}
          >
            <div>
              <p style={{ fontSize: "1.6rem", textAlign: "center" }}>
                Osiedle Nad Stawami
              </p>
              <p
                style={{
                  fontSize: "1.3rem",
                  textAlign: "center",
                  color: "green",
                }}
              >
                (Zrealizowana)
              </p>
            </div>
            <img src="/homepage/home_page_plan.jpg" />
            <p style={{ fontSize: ".95rem" }}>
              Osiedle nad Stawami cechuje się unikalnym klimatem. Umiejscowione
              jest w sąsiedztwie lasu, łąk oraz stawów. Osiedle posiada
              sprzyjającą lokalizację.
            </p>
          </Inwestycja>
          <Inwestycja
            onClick={() => {
              navigate("/osiedle-dworska");
            }}
          >
            <div>
              <p style={{ fontSize: "1.6rem", textAlign: "center" }}>
                Osiedle Dworska
              </p>
              <p
                style={{
                  fontSize: "1.3rem",
                  textAlign: "center",
                  color: "green",
                }}
              >
                (Zrealizowana)
              </p>
            </div>
            <img src="/homepage/home_page_small.png" />
            <p style={{ fontSize: ".95rem" }}>
              Nasza inwestycja "Osiedle Dworska" położona jest w Osowcu gmina
              Żabia Wola w północno- zachodniej części gminy. Osowiec ciągnie
              się od wschodu do zachodu przeważnie po jednej stronie drogi,
              wysadzonej dużymi topolami, które nadają bardzo duży urok wsi.
            </p>
          </Inwestycja>
        </InwestycjeContainer>
      </NaszeInwestycje>

      <OpisFirmy style={{ marginTop: "4rem" }}>
        <OpisFirmyHeading style={{ marginBottom: "2rem" }}>
          O Firmie
        </OpisFirmyHeading>

        <OpisFirmyTextFotka>
          <OpisFirmyText>
            Prezes Spółki wychowany w Grodzisku Mazowieckim, znający każdy
            zakątek miasta a także znający potrzeby mieszkańców, rozpoczął swoją
            przygodę z przedsiębiorstwem zakładając Hurtownię elektryczną
            „Merkurion” w 1990 r, dzięki czemu pozyskał bardzo duże
            doświadczenie z nawiązywaniem współpracy w każdej dziedzinie, oraz
            dużą rzeszę odbiorców. Głody pozyskiwania doświadczenia, a także
            poszerzając swoją działalność w 2013 roku w Bieszczadach otworzył
            Hotel Premium p.n. „Hotel Carpatia Bieszczadzki Gościniec” w Smerku,
            który cieszy się popularnością, wśród elity turystycznej. Dzięki
            ciężkiej pracy Prezes jest uznawany za uczciwego oraz godnego
            zaufania przedsiębiorce, dla którego celem jest zadowolenie każdego
            klienta. Zbiegiem lat firma tak mocno rozwinęła się na rynku, że
            powstała nowa firma KMK DEVELOPMENT SP. Z O.O.
          </OpisFirmyText>

          <img src="/polna/zewnatrz_polna.jpg" />
        </OpisFirmyTextFotka>

        <OpisFirmyHeading style={{ marginTop: "6rem", marginBottom: "2rem" }}>
          O Deweloperze
        </OpisFirmyHeading>

        <OpisFirmyTextFotka>
          <OpisFirmyText>
            Firma KMK Development rozpoczęła swoją przygodę z budownictwem w
            2020r. Jako nowy Deweloper na rynku, staramy się aby każda nasza
            inwestycja różniła się od innych proponowanych nieruchomości na
            terenie Grodziska Mazowieckiego i okolicach. Podczas realizacji
            naszych inwestycji zyskaliśmy reputację uczciwego oraz godnego
            zaufania Dewelopera na rynku. Specjalizujemy się w projektowaniu,
            budowie oraz sprzedaży domów wielorodzinnych z infrastrukturą
            pozwalającą niewątpliwie na bliskość z przyrodą a także z obecnością
            ciszy sprzyjającą atmosferze skupienia i relaksu, nabierania
            dystansu do codziennych obowiązków. Dzięki temu, że jesteśmy
            inwestorem oraz generalnym wykonawcą każdej naszej inwestycji
            pozwala nam to na indywidualne podejście do klienta proponując
            konkurencyjne ceny na rynku. KMK DEVELOPMENT SP. Z O.O. jest silnie
            dynamiczną oraz rozwijającą się firmą, której zadaniem jest
            budowanie z najwyższych jakościowo materiałów oraz spełnianie marzeń
            ludzi. Spółka współpracuje z wysoko wykwalifikowaną kadrą, dzięki
            której możemy pochwalić się ponadczasowymi oraz energooszczędnymi
            projektami. Każda nasza inwestycja budowana jest z pasją. Ciągła
            analiza rynku pozwala nam wychodzić naprzeciw oczekiwaniom klientów,
            nawet najbardziej wymagający klient będzie zadowolony z naszej
            współpracy. Spółka oferuje gotowe domy w stanie deweloperskim.
            Wyróżnia nas partnerskie a także indywidualne podejście do każdego
            naszego klienta. Wspieramy i pomagamy naszym klientom w pozyskiwaniu
            kredytu na zakup mieszkania.
          </OpisFirmyText>

          <img src="/dworska/dworska1.jpg" />
        </OpisFirmyTextFotka>
      </OpisFirmy>

      <SecondHeading>Lokalizacja Firmy KMK Dewelopment</SecondHeading>

      <Text>Grodzisk Mazowiecki ul. Królewska 14</Text>

      <Map
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4900.213878652558!2d20.658607!3d52.114183!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471948fa9eef24c1%3A0xbe37a2e38a00481d!2sKr%C3%B3lewska%2014%2C%2005-827%20Grodzisk%20Mazowiecki!5e0!3m2!1spl!2spl!4v1732365179137!5m2!1spl!2spl"
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      />
    </>
  );
};

export default Home;
